import styled from 'styled-components';
import { space, color, border } from 'styled-system';
import {
  IconButton,
  Row,
  Label,
  Input,
  Button,
} from '@d-lighted/design-system';
import Text from 'components/Texts/Text13';

import * as colors from 'constants/colors';
import * as screenSize from 'constants/screenSize';

export const MainRow = styled(Row)`
  width: 100%;
`;

export const TransparentIconButton = styled(IconButton)`
  background-color: transparent;
  width: auto;
  padding-right: 0;

  &:hover {
    background-color: transparent;
  }
`;

export const CalendarWrapper = styled.div`
  .dropdown__content {
    z-index: 4;
  }
`;

export const TagWrapper = styled.div`
  border-radius: 50px;
  display: flex;
  align-items: center;
  ${space}
  ${color}
  ${border}
`;

export const TagText = styled.p`
  font-family: Noto Sans JP;
  font-size: ${props => (props.fullscreenState ? '20px' : '13px')};
  color: ${colors.grayThin3};
`;

export const TagIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 1.5px;
  margin-right: 7px;
  ${color}
  ${border}
`;
export const ResourceHeaderText = styled(Text)`
  line-height: 1.5;
  width: calc(100% - ${props => (props.editMode ? '20px' : '30px')});
  white-space: break-spaces;
  margin-bottom: 6px;
  @media (min-width: ${screenSize.LScreen}px) {
    font-size: ${props => (props.fullScreenState ? '20px' : '16px')};
  }
  @media (min-width: ${screenSize.XLScreen}px) {
    font-size: ${props => (props.fullScreenState ? '20px' : '16px')};
  }
`;

export const ResourceCapacityText = styled(Text)`
  width: calc(100% - ${props => (props.editMode ? '20px' : '30px')});
  font-size: ${props => (props.fullScreenState ? '16px' : '11px')};
  font-weight: normal;
`;
export const CustomResourceHeader = styled.div`
  background-color: ${props => {
    if (props.busyResource && !props.editMode) {
      return colors.busyBGGray;
    }
    if (!props.visible) {
      return colors.lightGray;
    }
    return colors.white;
  }};
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: ${props => (props.editMode ? 'row' : 'column')};
  border-radius: 10px 10px 0 0;
  cursor: ${props => (props.editMode ? 'pointer' : 'default')};
`;

export const CustomForm = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0 16px;
  .confirm_button {
    background-color: ${colors.brandBlue};
  }
  .cancel_button {
    color: ${colors.brandBlue} !important;
    border: 2px solid ${colors.brandBlue};
  }
  .space_top {
    padding: 20px 0;
  }
`;

export const ModalTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${colors.darkBlue};
  padding-left: 8px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 24px;
  cursor: pointer;
`;

export const TitleLabel = styled(Label)`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${colors.darkBlue};
`;

export const InputField = styled(Input)`
  height: 48px;
  background: ${colors.lightGray};
  border-radius: 11px;
  border-color: ${colors.lightGray};
`;

export const ConfirmationDialogButton = styled(Button)`
  width: 28px;
  height: 47px;
  border-radius: 4px;
  font-weight: 600;
  margin-right: 8px;
`;

export const EventDetailsContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ColorIndicator = styled.div`
  border-radius: 4px;
  height: 20px;
  width: 20px;
  background-color: ${props => props.color};
  border: ${props => props.border || null};
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-top: 24px;
`;

export const MoreLoaderContainer = styled.div`
  z-index: 4;
  padding: 2px;
  position: absolute;
  top: 50%;
  right: 20px;
`;

export const EyeIconWrapper = styled.span`
  padding: 6px;
  border: ${props =>
    props.visible ? `1px solid ${colors.incomingEvent}` : '1px solid #BCE7F0'};
  color: ${colors.incomingEvent};
  border-radius: 6px;

  &:hover {
    box-shadow: ${props => (props.edit ? '0 0 1px 3px #B2E7F0' : 0)};
  }
`;

export const FullscreenHeaderWrapper = styled.header`
  height: 42.5px;
  width: 100%;
  padding: 24px 0px;
  margin-bottom: 20px;
  background-color: ${color.hoverGray};
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ExitFullScreenButton = styled(Button)`
  width: 170px;
  padding: 0px 4px;
  margin-right: 20px;
  background: ${colors.white};
  color: ${colors.grayThin3};

  &:hover {
    background: ${colors.white};
    color: ${colors.grayThin3};
  }

  &:focus {
    background: ${colors.white};
    color: ${colors.grayThin3};
  }
`;

export const ExitFullScreenButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const MainWrapper = styled.div`
  .rbc-day-slot {
    cursor: ${({ edit }) => (edit ? 'pointer' : 'default')};
  }
`;

export const DateHeaderBox = styled.div`
  height: ${p =>
    p.fullscreenState ? 'calc(100vh - 30px)' : 'calc(100vh - 70px)'};
  width: 100%;
  .rbc-time-header .rbc-time-header-content {
    border-bottom: ${p => (p.today ? 'none' : 'solid 1px #ddd')};
  }
`;

export const SignageModeLogo = styled.img`
  height: 50px;
  width: 200px;
`;

export const CountdownWrapper = styled.div`
  height: 50px;
  width: 50px;
  margin-left: auto;
  margin-top: 0px;
`;
