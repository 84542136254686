import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import DetailCard from 'components/DetailCard';
import CalendarCard from 'components/CalendarCard';
import { checkRoomIsBusy } from 'utils/eventUtils';
import { useGetSettingResourceApp } from 'containers/Home/hooks/useGetSettingResourceApp';
import { ResourceWrapper } from './components/styled';
import { useGetResourceEvent } from './hooks/useGetResourceEvent';
import { useCalendarResource } from './hooks/useCalendarResource';

const Resource = ({ resource, resourceId, intl, useNotify, ...props }) => {
  const {
    postCalendarEvent,
    calendarEvent,
    setInitialEvents,
    events,
    setTempEvent,
    tempEvent,
  } = useCalendarResource();

  const {
    fetchSettingResourceApp,
    settingResourceApp,
  } = useGetSettingResourceApp();

  const isRoomBusy = checkRoomIsBusy(resourceId, events);
  const [selectedDate, setSelectedDate] = useState(moment().toDate());

  const {
    events: allEvents,
    setDate,
    deleteCalendarEvent,
    forceGetResourceAndEvent,
  } = useGetResourceEvent(resourceId);

  useEffect(() => {
    setInitialEvents(allEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEvents]);

  useEffect(() => {
    const interval = setInterval(() => {
      forceGetResourceAndEvent();
    }, 300000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    fetchSettingResourceApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ResourceWrapper>
        <DetailCard resource={resource} isRoomBusy={isRoomBusy} />
        <CalendarCard
          postCalendarEvent={postCalendarEvent}
          calendarEvent={calendarEvent}
          resource={resource}
          events={events}
          setTempEvent={setTempEvent}
          tempEvent={tempEvent}
          resourceId={resourceId}
          setDate={setDate}
          deleteCalendarEvent={deleteCalendarEvent}
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
          allowDuplicateAllDayEvent={
            settingResourceApp.allowDuplicateAllDayEvent
          }
          {...props}
        />
      </ResourceWrapper>
    </>
  );
};

Resource.defaultProps = {
  isRoomBusy: false,
  intl: null,
};

Resource.propTypes = {
  resource: PropTypes.oneOfType([PropTypes.object, PropTypes.node]).isRequired,
  isRoomBusy: PropTypes.bool,
  intl: PropTypes.oneOfType([PropTypes.object]),
  useNotify: PropTypes.func.isRequired,
  resourceId: PropTypes.string.isRequired,
};

export default Resource;
