import React from 'react';
import { PropTypes } from 'prop-types';
import { Row } from '@d-lighted/design-system';

import {
  CustomResourceHeader,
  ResourceHeaderText,
  ResourceCapacityText,
  EyeIconWrapper,
} from './styled';

const ResourceHeader = ({
  resource,
  isRoomBusy,
  editMode,
  handleResourceVisibleChange,
  fullScreenState,
}) => {
  const { resourceTitle, capacity, resourceId, visible } = resource;
  return (
    <CustomResourceHeader
      busyResource={isRoomBusy(resourceId)}
      visible={visible}
      editMode={editMode}
      onClick={() => editMode && handleResourceVisibleChange(resourceId)}
    >
      {editMode ? (
        <>
          <Row>
            <ResourceHeaderText
              editMode={editMode}
              fullScreenState={fullScreenState}
            >
              {resourceTitle}
            </ResourceHeaderText>
            {capacity && (
              <ResourceCapacityText
                editMode={editMode}
                fullScreenState={fullScreenState}
              >
                ({capacity}人)
              </ResourceCapacityText>
            )}
          </Row>
          <EyeIconWrapper visible={visible} edit={editMode}>
            <i
              className={visible ? 'fa fa-eye' : 'fa fa-eye-slash'}
              aria-hidden="true"
            />
          </EyeIconWrapper>
        </>
      ) : (
        <>
          <ResourceHeaderText fullScreenState={fullScreenState}>
            {resourceTitle}
          </ResourceHeaderText>
          {capacity && (
            <ResourceCapacityText fullScreenState={fullScreenState}>
              ({capacity}人)
            </ResourceCapacityText>
          )}
        </>
      )}
    </CustomResourceHeader>
  );
};

ResourceHeader.defaultProps = {
  editMode: false,
  fullScreenState: false,
};

ResourceHeader.propTypes = {
  resource: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isRoomBusy: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  fullScreenState: PropTypes.bool,
  handleResourceVisibleChange: PropTypes.func.isRequired,
};

export default ResourceHeader;
