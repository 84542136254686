import * as userUtils from 'utils/userUtils';
import { useState } from 'react';

function useCurrentUser() {
  const [currentUser, setCurrentUserState] = useState(
    userUtils.getCurrentUser(),
  );

  const setCurrentUser = user => {
    setCurrentUserState(user);
    return userUtils.setCurrentUser(user);
  };

  return [currentUser, setCurrentUser];
}

export { useCurrentUser };
