import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';

import SettingGearSVG from 'images/ic_settings.svg';
import {
  setSearchDialogOpen,
  setSearchDialogType,
} from 'containers/Home/actions';
import {
  MobileHeaderWrapper,
  MobileHeaderContent,
  MobileHeaderLeft,
  MobileHeaderRight,
  MobileHeaderCenter,
  BackButton,
  GreenIcon,
  GearIcon,
} from './styled';

const MobileHeader = ({ title, children }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const openSearchDialog = dialogType => {
    dispatch(setSearchDialogType(dialogType));
    dispatch(setSearchDialogOpen(true));
  };

  return (
    <MobileHeaderWrapper>
      <MobileHeaderContent>
        <MobileHeaderLeft>
          {window.location.pathname !== '/' ? (
            <BackButton
              onClick={() => {
                history.goBack();
              }}
            >
              <GreenIcon icon={faArrowLeft} />
            </BackButton>
          ) : (
            <GearIcon
              src={SettingGearSVG}
              onClick={() => openSearchDialog('setting')}
            />
          )}
        </MobileHeaderLeft>

        <MobileHeaderCenter>{title}</MobileHeaderCenter>

        <MobileHeaderRight>{children}</MobileHeaderRight>
      </MobileHeaderContent>
    </MobileHeaderWrapper>
  );
};

MobileHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.any]).isRequired,
  children: PropTypes.node.isRequired,
};

export { MobileHeader };
