export const ACTIONS = {
  SIGN_OUT: 'sign_out',
  UPDATE_CREDENTIALS: 'update_credentials',
};

export function isReactNativeWebView() {
  return localStorage.getItem('isReactNative') || false;
}

function sendPayload(action, payload = {}) {
  window.ReactNativeWebView.postMessage(
    JSON.stringify({
      action,
      payload,
    }),
  );
}

export function signOut() {
  sendPayload(ACTIONS.SIGN_OUT);
}

function getAndParseLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key)) || {};
}

export function updateCredentials() {
  const currentUser = getAndParseLocalStorage('currentUser');
  const authHeaders = getAndParseLocalStorage('auth_headers');
  const refreshToken = localStorage.getItem('refresh_token');

  sendPayload(ACTIONS.UPDATE_CREDENTIALS, {
    currentUser,
    authHeaders,
    refreshToken,
  });
}

export function isRNWebViewUpdateCredentials() {
  if (isReactNativeWebView()) updateCredentials();
}
