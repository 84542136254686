import React from 'react';
import { FormattedMessage } from 'react-intl';
import IllustrationLayout from 'components/Illustrations/IllustrationLayout';
import IllustrationSetting from 'components/Illustrations/IllustrationSetting';
import messages from './messages';

function ResourceSettingGuideView() {
  return (
    <IllustrationLayout
      illustration={<IllustrationSetting />}
      summary={<FormattedMessage {...messages.summary} />}
      description={<FormattedMessage {...messages.description} />}
    />
  );
}

export default ResourceSettingGuideView;
