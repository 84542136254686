import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Text from 'components/Texts/Text14';
import messages from 'components/BigCalendar/messages';
import { LabelWithTooltip } from '@d-lighted/design-system';
import { isPremium, isAdmin, isTrial } from 'utils/userUtils';
import {
  DropDownContentWrapper,
  DropDownItem,
  GearButton,
  IconWrapper,
  SaveButton,
  DropDownItemContainer,
  TooltipTextContainer,
  PremiumText,
  LabelContainer,
} from './styled';
import LogoUploadModal from './ResourcesList/components/LogoUploadModal';

const DisplaySettingsButton = ({
  fullscreenState,
  editMode,
  handleEditModeChange,
  handleSaveChanges,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const [logoUploadModalOpen, setLogoUploadModalOpen] = useState(false);

  const handleToggleDropDown = () => {
    setDropdown(!dropdown);
  };
  function checkInSignageModal(node) {
    let currNode = node;
    while (currNode != null) {
      if (currNode?.matches('.signageModeModal')) {
        return true;
      }
      currNode = currNode.parentElement;
    }
    return false;
  }
  const handleClickOutside = event => {
    const targetElement = event.target;
    if (
      !(
        targetElement?.matches('#dropdownBtn') ||
        targetElement?.parentElement?.matches('#dropdownBtn')
      )
    )
      setDropdown(false);
  };

  const handleClickOutsideModal = event => {
    const targetElement = event.target;
    checkInSignageModal(targetElement);
    if (
      !(
        checkInSignageModal(targetElement) ||
        targetElement.parentNode?.matches('#uploadLogo')
      )
    ) {
      setLogoUploadModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('click', handleClickOutsideModal);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('click', handleClickOutsideModal);
    };
  });

  const handleFullScreenChange = () => {
    if (!fullscreenState) {
      document.documentElement.requestFullscreen();
    }
  };
  const openLogoUploadModal = () => {
    setLogoUploadModalOpen(!logoUploadModalOpen);
    document.addEventListener('click', handleClickOutside);
  };

  const items = [
    {
      id: 2,
      text: <FormattedMessage {...messages.selectTheMeetingRoomToDisplay} />,
      onClick: () => handleEditModeChange(),
    },
  ];
  if (isPremium() && isAdmin()) {
    items.unshift({
      id: 3,
      realId: 'uploadLogo',
      text: (
        <DropDownItemContainer>
          <FormattedMessage {...messages.logoupload} />
          {isTrial() && (
            <LabelContainer>
              <LabelWithTooltip
                bgColor="#eeebfa"
                fontColor="#6E52FF"
                position="bottom"
                labelText={
                  <PremiumText>
                    <FormattedMessage {...messages.premiumFeature} />
                  </PremiumText>
                }
                tooltipText={
                  <TooltipTextContainer>
                    <FormattedMessage {...messages.premiumFeatureTooltipText} />
                  </TooltipTextContainer>
                }
                width={340}
              />
            </LabelContainer>
          )}
        </DropDownItemContainer>
      ),
      onClick: () => openLogoUploadModal(),
    });
  }
  if (isPremium() && isAdmin()) {
    items.unshift({
      id: 1,
      text: (
        <DropDownItemContainer>
          <FormattedMessage {...messages.fullScreen} />
          {isTrial() && (
            <LabelContainer>
              <LabelWithTooltip
                bgColor="#eeebfa"
                fontColor="#6E52FF"
                position="bottom"
                labelText={
                  <PremiumText>
                    <FormattedMessage {...messages.premiumFeature} />
                  </PremiumText>
                }
                tooltipText={
                  <TooltipTextContainer>
                    <FormattedMessage {...messages.premiumFeatureTooltipText} />
                  </TooltipTextContainer>
                }
                width={340}
              />
            </LabelContainer>
          )}
        </DropDownItemContainer>
      ),
      onClick: () => handleFullScreenChange(),
    });
  }

  const onSaveChangesClick = () => {
    handleSaveChanges();
    handleEditModeChange();
  };

  return (
    <>
      {!editMode ? (
        <div style={{ position: 'relative' }}>
          <GearButton
            id="dropdownBtn"
            className="dropdown-button"
            onClick={() => handleToggleDropDown()}
          >
            <FormattedMessage {...messages.displaySettings} />
            <IconWrapper
              onClick={event => {
                event.stopPropagation();
                handleToggleDropDown();
              }}
            >
              <i className="fa fa-cog" aria-hidden="true" />
            </IconWrapper>
          </GearButton>
          {dropdown && (
            <DropDownContentWrapper duration="0.2s">
              {items.map(item => (
                <DropDownItem
                  id={item.realId}
                  onClick={() => item.onClick()}
                  key={item.id}
                >
                  <Text>{item.text}</Text>
                </DropDownItem>
              ))}
            </DropDownContentWrapper>
          )}
          {logoUploadModalOpen && (
            <LogoUploadModal openLogoUploadModal={openLogoUploadModal} />
          )}
        </div>
      ) : (
        <SaveButton onClick={() => onSaveChangesClick()}>
          <FormattedMessage {...messages.saveYourChanges} />
        </SaveButton>
      )}
    </>
  );
};

DisplaySettingsButton.defaultProps = {
  fullscreenState: false,
  editMode: false,
};

DisplaySettingsButton.propTypes = {
  fullscreenState: PropTypes.bool,
  editMode: PropTypes.bool,
  handleEditModeChange: PropTypes.func.isRequired,
  handleSaveChanges: PropTypes.func.isRequired,
};

export default DisplaySettingsButton;
