import styled from 'styled-components';
import * as color from '../../../constants/colors';
import * as screenSize from '../../../constants/screenSize';

const cellWidth = 173.5;
const cellPerScreen = Math.floor((window.innerWidth - 115) / cellWidth);

export const BigCalendarWrapper = styled.div`
  font-family: 'Roboto';
  width: 100%;
  .rbc-time-view-resources {
    width: ${props =>
      props.fullscreenState ? `${cellWidth * cellPerScreen + 65}px` : '100%'};
  }
  .rbc-time-view,
  .rbc-time-gutter > .rbc-timeslot-group,
  .rbc-time-content {
    border: 0;
  }

  .rbc-timeslot-group {
    min-height: 60px;
  }

  .rbc-time-header {
    border-right: 0 !important;
    min-height: 60px;
  }

  .rbc-time-gutter,
  .rbc-time-header-gutter {
    box-shadow: 2.5px 0 5px 0 rgba(115, 118, 140, 0.16);
  }

  .rbc-time-content > * + * > * {
    border-left: 0;
  }

  .rbc-now,
  .rbc-today {
    border-top: 1px solid #ddd;
  }

  .rbc-day-slot .rbc-time-slot {
    border-top: 0;
  }

  .rbc-time-slot.rbc-now {
    color: #705bcf;
  }

  .rbc-time-gutter > .rbc-timeslot-group > .rbc-time-slot {
    background: ${color.lightGray} !important;
    margin-top: -11px;
  }

  .rbc-label {
    font-size: 12px;
    color: ${color.grayThin3};
    @media (min-width: ${screenSize.LScreen}px) {
      font-size: ${props => (props.fullscreenState ? '16px' : '12px')};
    }
    @media (min-width: ${screenSize.XLScreen}px) {
      font-size: ${props => (props.fullscreenState ? '20px' : '12px')};
    }
  }

  .rbc-time-view-resources,
  .rbc-time-gutter,
  .rbc-time-view-resources,
  .rbc-time-header-gutter {
    background-color: ${color.hoverGray} !important;
  }

  .rbc-time-header-content {
    border-left: 1px solid ${color.hoverGray};

    margin-right: 2px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px 10px 0 0;

    min-width: 170.75px;
    max-width: 170.75px;
    width: 170.75px;
    @media (min-width: ${screenSize.LScreen}px) {
      min-width: ${props => (props.fullscreenState ? '170.75px' : '170.75px')};
      max-width: ${props => (props.fullscreenState ? '170.75px' : '170.75px')};
      width: ${props => (props.fullscreenState ? '170.75px' : '170.75px')};
    }
    @media (min-width: ${screenSize.XLScreen}px) {
      min-width: ${props => (props.fullscreenState ? '170.75px' : '170.75px')};
      max-width: ${props => (props.fullscreenState ? '170.75px' : '170.75px')};
      width: ${props => (props.fullscreenState ? '170.75px' : '170.75px')};
    }
  }

  .rbc-time-header-content > .rbc-row-resource {
    display: flex;
    align-items: center;
    min-height: 80px;
    color: ${color.grayThin3};
    font-size: 16px;
    width: 100%;
  }

  .rbc-allday-cell {
    display: none;
  }

  .rbc-header {
    color: ${color.darkBlue};
    padding: 0;
    height: 100%;
  }

  .rbc-time-header-content > .rbc-row.rbc-row-resource,
  .rbc-header {
    border-bottom: 0;
  }

  .rbc-event-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rbc-current-time-indicator {
    background-color: ${color.currentTimeIndicator};
    height: 2px;
    min-width: calc(100% + 2px);
  }

  .rbc-today {
    background-color: white;
  }

  .rbc-time-content > .rbc-day-slot {
    margin-right: 2px;
  }

  .rbc-time-column:last-child {
    margin-right: ${props => (props.isLoadingMore ? '80px' : '2px')};
  }

  .rbc-time-header-content:last-child {
    margin-right: ${props => (props.isLoadingMore ? '80px' : '2px')};
  }

  .rbc-time-view-resources .rbc-day-slot {
    min-width: 170px;
    max-width: 170px;
    width: 170px;
    @media (min-width: ${screenSize.LScreen}px) {
      min-width: ${props => (props.fullscreenState ? '170px' : '170px')};
      max-width: ${props => (props.fullscreenState ? '170px' : '170px')};
      width: ${props => (props.fullscreenState ? '170px' : '170px')};
    }
    @media (min-width: ${screenSize.XLScreen}px) {
      min-width: ${props => (props.fullscreenState ? '170px' : '170px')};
      max-width: ${props => (props.fullscreenState ? '170px' : '170px')};
      width: ${props => (props.fullscreenState ? '170px' : '170px')};
    }
  }

  .rbc-time-gutter {
    min-width: 60px;
    max-width: 60px;
    width: 60px;
    @media (min-width: ${screenSize.LScreen}px) {
      min-width: ${props => (props.fullscreenState ? '80px' : '60px')};
      max-width: ${props => (props.fullscreenState ? '80px' : '60px')};
      width: ${props => (props.fullscreenState ? '80px' : '60px')};
    }
    @media (min-width: ${screenSize.XLScreen}px) {
      min-width: ${props => (props.fullscreenState ? '60px' : '60px')};
      max-width: ${props => (props.fullscreenState ? '60px' : '60px')};
      width: ${props => (props.fullscreenState ? '60px' : '60px')};
    }
  }

  .rbc-label {
    min-width: 60px !important;
    max-width: 60px !important;
    width: 60px !important;
    @media (min-width: ${screenSize.LScreen}px) {
      min-width: ${props =>
        props.fullscreenState ? '80px !important' : '60px'};
      max-width: ${props =>
        props.fullscreenState ? '80px !important' : '60px'};
      width: ${props => (props.fullscreenState ? '80px !important' : '60px')};
    }
    @media (min-width: ${screenSize.XLScreen}px) {
      min-width: ${props =>
        props.fullscreenState ? '60px !important' : '60px'};
      max-width: ${props =>
        props.fullscreenState ? '60px !important' : '60px'};
      width: ${props => (props.fullscreenState ? '60px !important' : '60px')};
    }
  }

  .rbc-event {
    left: 5px !important;
  }

  .rbc-slot-selection {
    width: 100%;
    z-index: 10;
    position: absolute;
    cursor: default;
    background-color: ${color.primaryGreen};
    opacity: 0.8;
    border-radius: 3px;
    color: ${color.white};
    font-size: 13px;
    padding: 3px;
  }
`;
