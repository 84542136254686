import React from 'react';
import styled from 'styled-components';
import { Row, Col, Label, Heading } from '@d-lighted/design-system';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import * as color from 'constants/colors';
import messages from './messages';
import { Card, StatusWrapper, StatusText } from './components/styled';

const LimitedHeader = styled(Heading)`
  font-weight: 600;
  overflow-wrap: break-word;
  max-width: 80vw;
`;

function DetailCard({ resource, isRoomBusy }) {
  const status = isRoomBusy ? (
    <FormattedMessage {...messages.inUse} />
  ) : (
    <FormattedMessage {...messages.vacant} />
  );

  return (
    <Card>
      <Row>
        <Col display="flex" alignItems="center">
          <LimitedHeader size={2} style={{ color: color.blackTitle }}>
            {resource?.resourceTitle}
          </LimitedHeader>
          <StatusWrapper isRoomBusy={isRoomBusy}>
            <StatusText isRoomBusy={isRoomBusy}>{status}</StatusText>
          </StatusWrapper>
        </Col>
      </Row>
      {resource?.capacity && (
        <Row mt="6px">
          <Col xs={3}>
            <Label fontSize="12px" color={color.grayLabel} fontWeight="500">
              <FormattedMessage {...messages.cap} />
            </Label>
          </Col>
          <Col>
            <Label fontSize="12px" color={color.darkBlue} fontWeight="500">
              {resource?.capacity}
            </Label>
          </Col>
        </Row>
      )}
      {resource?.providerType !== 'microsoft' && (
        <Row mt="6px">
          <Col xs={3}>
            <Label fontSize="12px" color={color.grayLabel} fontWeight="500">
              <FormattedMessage {...messages.building} />
            </Label>
          </Col>
          <Col xs>
            <Label fontSize="12px" color={color.darkBlue} fontWeight="500">
              {resource?.buildingId}
            </Label>
          </Col>
        </Row>
      )}
    </Card>
  );
}

DetailCard.propTypes = {
  resource: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isRoomBusy: PropTypes.bool.isRequired,
};

export default DetailCard;
