import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Text16 from 'components/Texts/Text16';
import * as color from 'constants/colors';
import messages from './messages';
import {
  BuildingText,
  SelectedBuildingText,
  CustomBuildingModal,
  ModalWrapper,
  ModalHeading,
  CloseIcon,
  ModalBody,
  BuildingsWrapper,
  BuildingRow,
  SelectButton,
  SelectedButton,
  SecondaryButton,
} from './components/styled';

function BuildingSelector({ buildings, setBuilding, selectedBuilding }) {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  const buildingSelectors = buildings?.map(b => {
    return (
      <BuildingRow key={b?.building_id}>
        <Text16 color="black" style={{ fontWeight: 200 }}>
          {b?.building_name}
        </Text16>
        {b?.building_id !== selectedBuilding?.building_id ||
        !selectedBuilding?.building_id ? (
          <SelectButton invertOnHover={false} onClick={() => setBuilding(b)}>
            <FormattedMessage {...messages.buildingSelect} />
          </SelectButton>
        ) : (
          <SelectedButton>
            <FormattedMessage {...messages.buildingSelected} />
          </SelectedButton>
        )}
      </BuildingRow>
    );
  });

  return (
    <>
      <BuildingText>
        <FormattedMessage {...messages.building} />:{' '}
        <SelectedBuildingText onClick={toggleModalOpen}>
          {selectedBuilding?.building_name || (
            <FormattedMessage {...messages.notSelected} />
          )}
        </SelectedBuildingText>
      </BuildingText>

      <CustomBuildingModal
        isOpen={modalOpen}
        toggleModal={toggleModalOpen}
        onBackgroundClick={toggleModalOpen}
        onEscapeKeydown={toggleModalOpen}
        width="500px"
        height="90vh"
      >
        <div className="modalContent">
          <ModalWrapper>
            <ModalHeading>
              <h3>
                <FormattedMessage {...messages.title} />
              </h3>
              <CloseIcon onClick={() => setModalOpen(false)} />
            </ModalHeading>

            <ModalBody>
              <BuildingsWrapper>
                {buildings?.length > 0 ? (
                  buildingSelectors
                ) : (
                  <Text16 color="black" mb={20} textAlign="center">
                    <FormattedMessage {...messages.noBuilding} />
                  </Text16>
                )}
              </BuildingsWrapper>
              <Text16
                color={color.black}
                mb={20}
                style={{ lineHeight: 1.5, fontWeight: 200 }}
              >
                <FormattedMessage {...messages.notSelectedWarning} />
              </Text16>
              <SecondaryButton width="100%" onClick={() => setBuilding({})}>
                <FormattedMessage {...messages.unselect} />
              </SecondaryButton>
            </ModalBody>
          </ModalWrapper>
        </div>
      </CustomBuildingModal>
    </>
  );
}

BuildingSelector.propTypes = {
  buildings: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setBuilding: PropTypes.func.isRequired,
  selectedBuilding: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default BuildingSelector;
