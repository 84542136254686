import { defineMessages } from 'react-intl';

export default defineMessages({
  vacancy: {
    id: 'bigCalendar.calendarHeader.vacancy',
    defaultMessage: 'Vacant',
  },
  inUse: {
    id: 'bigCalendar.calendarHeader.inUse',
    defaultMessage: 'In Use',
  },
  checkedOut: {
    id: 'bigCalendar.calendarHeader.checkedOut',
    defaultMessage: 'Checked Out',
  },
  inAMeeting: {
    id: 'bigCalendar.calendarHeader.inAMeeting',
    defaultMessage: 'In a Meeting',
  },
  reserved: {
    id: 'bigCalendar.calendarHeader.reserved',
    defaultMessage: 'Reserved',
  },
  downloadButtonText: {
    id: 'bigCalendar.calendarHeader.downloadCsv.buttonText',
    defaultMessage: 'Download MeetingRooms availabilty',
  },
  filterButtonText: {
    id: 'bigCalendar.calendarHeader.dropdown.buttonText',
    defaultMessage: 'Filter available MeetingRooms',
  },
  notSpecified: {
    id: 'bigCalendar.calendarHeader.dropdown.notSpecified',
    defaultMessage: 'Not Specified',
  },
  fifteenMins: {
    id: 'bigCalendar.calendarHeader.dropdown.fifteenMinutes',
    defaultMessage: 'Next 15 minutes',
  },
  thirtyMins: {
    id: 'bigCalendar.calendarHeader.dropdown.thirtyMinutes',
    defaultMessage: 'Next 30 minutes',
  },
  sixtyMins: {
    id: 'bigCalendar.calendarHeader.dropdown.sixtyMinutes',
    defaultMessage: 'Next 60 minutes',
  },
  titleLabel: {
    id: 'eventCreateDialog.title.label',
    defaultMessage: 'Title',
  },
  reserveButton: {
    id: 'reserve.button',
    defaultMessage: 'Reserve',
  },
  privateEvent: {
    id: 'resourceCalendarEvent.privateEvent',
    defaultMessage: 'Private event',
  },
  privateDescription: {
    id: 'resourceCalendarEvent.privateEvent.description',
    defaultMessage:
      'Sorry, the detail can not be seen due to the private event.',
  },
  meeting: {
    id: 'resourceCalendarEvent.meeting',
    defaultMessage: '打ち合わせ',
  },
  overlap: {
    id: 'bigCalendar.calendarBody.selectionOverlaps',
    defaultMessage:
      'The time you specified has already been reserved for another appointment.',
  },
  confirmDelete: {
    id: 'eventDetailsDialog.delete.confirmation',
    defaultMessage: 'Do you really want to delete this appointment?',
  },
  confirmCheckIn: {
    id: 'eventDetailsDialog.checkin.confirmation',
    defaultMessage: 'Are you sure you want to check in?',
  },
  confirmCheckOut: {
    id: 'eventDetailsDialog.checkout.confirmation',
    defaultMessage: 'Are you sure you want to check out?',
  },
  displaySettings: {
    id: 'bigCalendar.calendarHeader.dropdown.displaySettings',
    defaultMessage: 'Display Settings',
  },
  fullScreen: {
    id: 'bigCalendar.calendarHeader.dropdown.fullScreen',
    defaultMessage: 'Full screen',
  },
  endFullScreen: {
    id: 'bigCalendar.calendarHeader.dropdown.endFullScreen',
    defaultMessage: 'End full screen',
  },
  conferenceRoom: {
    id: 'bigCalendar.calendarHeader.dropdown.conferenceRoom',
    defaultMessage: 'Conference room',
  },
  selectTheMeetingRoomToDisplay: {
    id: 'bigCalendar.calendarHeader.dropdown.selectTheMeetingRoomToDisplay',
    defaultMessage: 'Select the meeting room to display',
  },
  saveYourChanges: {
    id: 'bigCalendar.calendarHeader.dropdown.saveYourChanges',
    defaultMessage: 'Save your changes',
  },
  logoupload: {
    id: 'bigCalendar.calendarHeader.dropdown.logoupload',
    defaultMessage: 'Logo Upload',
  },
  premiumFeature: {
    id: 'bigCalendar.calendarHeader.dropdown.premiumFeature',
    defaultMessage: 'Premium',
  },
  premiumFeatureTooltipText: {
    id: 'bigCalendar.calendarHeader.dropdown.premiumFeatureTooltipText',
    defaultMessage: 'This feature is only available for our Premium plan.',
  },
  checkin: {
    id: 'checkin.button',
    defaultMessage: 'Checkin',
  },
  checkout: {
    id: 'checkout.button',
    defaultMessage: 'Checkout',
  },
});
