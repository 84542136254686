import React, { useState } from 'react';
import styled from 'styled-components';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { ReceptionistThemeProvider } from '@d-lighted/design-system';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import SnackbarProvider from 'react-simple-snackbar';
import { PersistGate } from 'redux-persist/integration/react';

import LoadingBar from 'components/LoadingBar';
import LocaleProvider from 'providers/LocaleProvider';
import { useI18Notification } from 'hooks/useI18Notification';
import { routeGenerator } from 'routes';
import { store, persistor } from 'redux/store';
import { getCurrentUser, getSubdomain } from 'utils/userUtils';

const SnackBarWrapperStyle = styled.div`
  div[class^='Snackbar_snackbar-wrapper__'] {
    z-index: 2000;
  }
`;

function App(props) {
  const [currentUser, setCurrentUser] = useState(getCurrentUser() || {});
  const authenticated = currentUser?.id != null;
  const subdomain = getSubdomain();

  const [ResponseMessage, useNotify] = useI18Notification();

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
  };

  TagManager.initialize(tagManagerArgs);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LoadingBar />
        <Router>
          <ReceptionistThemeProvider>
            <LocaleProvider>
              <SnackBarWrapperStyle>
                <SnackbarProvider>
                  <ResponseMessage />
                  <Switch>
                    {routeGenerator({
                      ...props,
                      authenticated,
                      useNotify,
                      currentUser,
                      subdomain,
                      setCurrentUser,
                    })}

                    {/* Remove comments if we don't need signing in */}
                    {/* <Redirect to="/sign_in" /> */}
                  </Switch>
                </SnackbarProvider>
              </SnackBarWrapperStyle>
            </LocaleProvider>
          </ReceptionistThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
