import React, { useEffect, useState } from 'react';
import { Row, Col, Label } from '@d-lighted/design-system';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import IconClock from 'components/Icons/IconClock';
import IconDoorOpen from 'components/Icons/IconDoorOpen';
import IconTimes from 'components/Icons/IconTimes';
import IconUserFriends from 'components/Icons/IconUserFriends';
import { WordBreakLabel } from 'components/_commons/styled';
import * as colors from 'constants/colors';
import { getCurrentUser } from 'utils/userUtils';
import GarbageSvg from 'images/ic_garbage.svg';
import {
  EventDetailsContent,
  ModalTitle,
  ColorIndicator,
  CustomEventModal,
  Icon,
} from './styled';
import messages from '../messages';

function EventDetailsDialog({
  selectedEvent,
  onCancel,
  timeFormat,
  generateEventStatus,
  onDelete,
  ...otherProps
}) {
  const [isRelated, setIsRelated] = useState(false);
  const formattedStartTime =
    moment(selectedEvent.start).format('MMMM Do ') +
    moment(selectedEvent.start).locale('en').format(timeFormat);
  const formattedEndTime = moment(selectedEvent.end)
    .locale('en')
    .format(timeFormat);

  const currentUser = getCurrentUser();
  const currentUserEmails = [currentUser.email?.toLowerCase()];

  if (currentUser.calendar_email != null || currentUser.calendar_id) {
    currentUserEmails.push(currentUser.calendar_email?.toLowerCase());
    currentUserEmails.push(currentUser.calendar_id?.toLowerCase());
  } else {
    currentUserEmails.push(
      currentUser.company?.meetingroom_provider_email?.toLowerCase(),
    );
  }

  const checkRelatedUser = () => {
    if (currentUser.company.resource_provider === 'garoon') {
      // NOTE: 参加者に外部連携アカウントまたはログインアカウントが存在するかどうか
      const attendeeUserIds = selectedEvent?.attendees.map(
        attendee => attendee.garoonUserId,
      );
      const attendeeUserCodes = selectedEvent?.attendees.map(
        attendee => attendee.garoonUserCode,
      );
      return (
        attendeeUserIds.includes(currentUser.garoon_user_id) ||
        attendeeUserCodes.includes(currentUser.email)
      );
    }
    if (currentUser.company.is_subcompany) {
      const attendeeEmails = selectedEvent?.attendees.map(attendee =>
        attendee.email.toLowerCase(),
      );

      return attendeeEmails.some(email => currentUserEmails.includes(email));
    }

    return (
      currentUserEmails.includes(
        selectedEvent?.organizer?.email?.toLowerCase(),
      ) ||
      currentUserEmails.includes(selectedEvent?.creator?.email?.toLowerCase())
    );
  };

  useEffect(() => {
    if (selectedEvent.attendees != null) {
      setIsRelated(checkRelatedUser);
    } else {
      setIsRelated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvent]);

  let indicatorColor = '';
  let border;
  switch (
    generateEventStatus(selectedEvent.checkedInAt, selectedEvent.checkedOutAt)
  ) {
    case 'done':
      indicatorColor = colors.doneEventGray;
      break;
    case 'busy':
      indicatorColor = colors.busyEvent;
      break;
    default:
      indicatorColor = colors.white;
      border = `solid 0.5px ${colors.primaryLightGreen}`;
      break;
  }

  const attendeeNames = (selectedEvent?.attendees || [])
    .filter(attendee => !attendee.resource)
    .map(attendee => attendee.name || attendee.email);

  const isPrivate =
    selectedEvent.visibility === 'private' ||
    selectedEvent.visibility === 'confidential' ||
    selectedEvent.visibility === 'PRIVATE'; // NOTE: Garoonの場合

  if (isPrivate) {
    return (
      <CustomEventModal {...otherProps} height="auto">
        <EventDetailsContent>
          <Row padding="16px 16px 14px" width="100%" alignItems="center">
            <Col>
              <ColorIndicator color={indicatorColor} border={border} />
            </Col>
            <Col xs>
              <ModalTitle>
                <FormattedMessage {...messages.privateEvent} />
              </ModalTitle>
            </Col>
            <Col>
              <IconTimes fill={colors.grayThin} onClick={onCancel} />
            </Col>
          </Row>
          <Row padding="0 56px 16px" width="100%">
            <Col xs>
              <Label>
                <FormattedMessage {...messages.privateDescription} />
              </Label>
            </Col>
          </Row>
        </EventDetailsContent>
      </CustomEventModal>
    );
  }

  return (
    <CustomEventModal {...otherProps} height="auto">
      <EventDetailsContent>
        <Row padding="16px 16px 14px" width="100%" alignItems="center">
          <Col>
            <ColorIndicator color={indicatorColor} border={border} />
          </Col>
          <Col xs>
            <ModalTitle>{selectedEvent.name}</ModalTitle>
          </Col>
          {isRelated && (
            <Col>
              <Icon src={GarbageSvg} onClick={onDelete} />
            </Col>
          )}
          <Col>
            <IconTimes fill={colors.grayThin} onClick={onCancel} />
          </Col>
        </Row>

        <Row padding="0 56px 16px" width="100%">
          <Col xs>
            <Row pb="8px" alignItems="center">
              <Col>
                <IconClock fill={colors.grayHeading} width={14} height={14} />
              </Col>
              <Col xs>
                <Label>{`${formattedStartTime} - ${formattedEndTime}`}</Label>
              </Col>
            </Row>
            <Row pb="8px" alignItems="center">
              <Col>
                <IconDoorOpen
                  fill={colors.grayHeading}
                  width={14}
                  height={14}
                />
              </Col>
              <Col xs>
                <WordBreakLabel>{selectedEvent.resourceTitle}</WordBreakLabel>
              </Col>
            </Row>
            <Row pb="8px" alignItems="center">
              <Col>
                <IconUserFriends
                  fill={colors.grayHeading}
                  width={14}
                  height={14}
                />
              </Col>
              <Col display="flex" flexDirection="column" xs>
                {attendeeNames.map((attendeeName, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <WordBreakLabel key={index}>{attendeeName}</WordBreakLabel>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </EventDetailsContent>
    </CustomEventModal>
  );
}

EventDetailsDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  selectedEvent: PropTypes.oneOfType([PropTypes.object]).isRequired,
  timeFormat: PropTypes.string.isRequired,
  generateEventStatus: PropTypes.func.isRequired,
};

export default EventDetailsDialog;
