import React from 'react';
import { Row, Col, Label } from '@d-lighted/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import * as color from 'constants/colors';
import enterFetchIcon from 'images/ic_enter_fetching.png';
import exitFetchIcon from 'images/ic_exit_fetching.png';
import messages from '../messages';
import {
  CustomConfirmationModal,
  ConfirmationContainer,
  ConfirmationDialogButton,
  CancelDialogButton,
  ConfirmationTitle,
  RoomCol,
  CustomForm,
  InputField,
  StyledTimeSelect,
  RoomTitleLabel,
  FetchIndicatorWrapper,
  DivOne,
  DivTwo,
  DivThree,
  FetchingIcon,
} from './styled';

function ConfirmationDialog({
  onConfirm,
  onCancel,
  calendarEvent,
  isFetching,
  fetchingType,
  statusCode,
  ...otherProps
}) {
  const intl = useIntl();

  const generateStatus = calEvent => {
    if (calEvent.meeting === null && calEvent.calendarEvent) return 'enter';
    if (calEvent.meeting) return 'exit';
    if (calEvent.instantMeeting?.allowed) return 'instantMeet';
    if (
      !calEvent.meeting &&
      !calEvent.calendarEvent &&
      !calEvent.instantMeeting?.allowed
    )
      return 'notAllow';
    return null;
  };

  const status = generateStatus(calendarEvent);

  const generateModalHeight = () => {
    switch (status) {
      case 'enter':
      case 'exit':
        return '324px';
      case 'instantMeet':
        return '476px';
      case 'notAllow':
        return '210px';
      default:
        return 'auto';
    }
  };

  if (isFetching) {
    return (
      <CustomConfirmationModal
        {...otherProps}
        width="90vw"
        height="324px"
        maxHeight="67%"
        mt="-168px"
      >
        <ConfirmationContainer>
          <ConfirmationTitle className="space_top">
            {(() => {
              switch (fetchingType) {
                case 'enter':
                case 'instantMeet':
                  return <FormattedMessage {...messages.fetchingEnterTitle} />;
                case 'exit':
                  return <FormattedMessage {...messages.fetchingExitTitle} />;
                case 'default':
                default:
                  return <FormattedMessage {...messages.fetchingTitle} />;
              }
            })()}
          </ConfirmationTitle>
          {(() => {
            switch (fetchingType) {
              case 'enter':
                return <FetchingIcon src={enterFetchIcon} />;
              case 'exit':
                return <FetchingIcon src={exitFetchIcon} />;
              case 'instantMeet':
              case 'default':
              default:
                return (
                  <FetchIndicatorWrapper>
                    <DivOne />
                    <DivTwo />
                    <DivThree />
                  </FetchIndicatorWrapper>
                );
            }
          })()}
        </ConfirmationContainer>
      </CustomConfirmationModal>
    );
  }

  return (
    <CustomConfirmationModal
      {...otherProps}
      width="90vw"
      height={generateModalHeight()}
      maxHeight="68vh"
      mt="-168px"
      overFlow={status === 'instantMeet'}
    >
      <ConfirmationContainer className="space_evenly">
        <ConfirmationTitle>
          {(() => {
            switch (status) {
              case 'enter':
              case 'instantMeet':
                return (
                  <FormattedMessage {...messages.entranceConfirmationTitle} />
                );
              case 'exit':
                return <FormattedMessage {...messages.exitTitle} />;
              case 'notAllow':
              default:
                return <FormattedMessage {...messages.noneTitle} />;
            }
          })()}
        </ConfirmationTitle>

        {status !== 'instantMeet' && (
          <Row
            width="100%"
            display={status === 'notAllow' ? 'none !important' : null}
          >
            <RoomCol xs={10} xsOffset={1}>
              <RoomTitleLabel>
                {calendarEvent?.calendarEvent?.name}
              </RoomTitleLabel>
            </RoomCol>
          </Row>
        )}

        {status === 'instantMeet' && (
          <Form
            onSubmit={values => onConfirm(status, values)}
            render={({ handleSubmit }) => (
              <CustomForm onSubmit={handleSubmit}>
                <Label
                  color={color.grayThin3}
                  fontSize="16px"
                  fontWeight="normal"
                  style={{ lineHeight: '26px' }}
                >
                  <FormattedMessage {...messages.instaMeetNameLabel} />
                </Label>
                <Field
                  name="name"
                  component={InputField}
                  placeholder={intl.formatMessage(
                    messages.instaMeetInputPlaceholder,
                  )}
                  mt="10px"
                />

                <Label
                  color={color.grayThin3}
                  fontSize="16px"
                  fontWeight="normal"
                  style={{
                    lineHeight: '26px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <FormattedMessage {...messages.instaMeetTimeLabel} />
                </Label>
                <Row alignItems="center" width="100%">
                  <Field
                    name="time"
                    initialValue={
                      calendarEvent?.instantMeeting?.durationOptions[0]
                    }
                  >
                    {props => (
                      <StyledTimeSelect {...props.input}>
                        {calendarEvent?.instantMeeting?.durationOptions.map(
                          duration => (
                            <option key={duration} value={duration}>
                              {duration}
                            </option>
                          ),
                        )}
                      </StyledTimeSelect>
                    )}
                  </Field>
                  <Label
                    fontWeight={500}
                    fontSize="18px"
                    color={color.darkBlue}
                  >
                    <FormattedMessage {...messages.timeUnit} />
                  </Label>
                </Row>

                <ConfirmationDialogButton
                  marginTop="23px"
                  className="confirm_button"
                  type="submit"
                >
                  <FormattedMessage
                    {...messages.entranceConfirmationAffirmative}
                  />
                </ConfirmationDialogButton>
              </CustomForm>
            )}
          />
        )}

        <Row>
          <Col width="100%" padding="0 25px !important">
            <Row
              display={
                status === 'notAllow' || status === 'instantMeet'
                  ? 'none !important'
                  : null
              }
            >
              <Col display="flex" justifyContent="center" width="100%">
                <ConfirmationDialogButton
                  marginBottom="16px"
                  onClick={() => onConfirm(status)}
                  className="confirm_button"
                >
                  {status === 'exit' ? (
                    <FormattedMessage {...messages.exitAffirmative} />
                  ) : (
                    <FormattedMessage
                      {...messages.entranceConfirmationAffirmative}
                    />
                  )}
                </ConfirmationDialogButton>
              </Col>
            </Row>

            <Row>
              <Col display="flex" justifyContent="center" width="100%">
                <CancelDialogButton outlined onClick={onCancel}>
                  <FormattedMessage
                    {...messages.confirmationNegative}
                    className="cancel_button"
                  />
                </CancelDialogButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </ConfirmationContainer>
    </CustomConfirmationModal>
  );
}

ConfirmationDialog.propTypes = {
  onConfirm: PropTypes.oneOfType([PropTypes.func]).isRequired,
  onCancel: PropTypes.oneOfType([PropTypes.func]).isRequired,
  calendarEvent: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isFetching: PropTypes.bool.isRequired,
  fetchingType: PropTypes.string,
  statusCode: PropTypes.number,
  input: PropTypes.oneOfType([PropTypes.object]),
};

ConfirmationDialog.defaultProps = {
  statusCode: null,
  input: {},
  fetchingType: 'default',
};

export default ConfirmationDialog;
