import axios from 'axios';

function useOneTimeToken() {
  const requestOneTimeToken = async () => {
    const result = await axios.post('auth/one_time_token');

    if (result.status < 300) {
      return result.data?.token;
    }

    return null;
  };

  const openWeb2RouteWithToken = async url => {
    const token = await requestOneTimeToken();

    // TODO: Maybe add here some error
    if (!token) return;

    const params = {
      one_time_token: token,
      client_id: (JSON.parse(localStorage.getItem('auth_headers')) || {})
        .client,
      return_to: url,
    };
    window.open(
      `${window.location.protocol}//${
        process.env.REACT_APP_WEB_PATH
      }sign_in?${new URLSearchParams(params).toString()}`,
      '_blank',
    );
  };

  return { requestOneTimeToken, openWeb2RouteWithToken };
}

export { useOneTimeToken };
