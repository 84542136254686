import { hideLoading, showLoading } from 'react-redux-loading-bar';
import moment from 'moment';
import axios from 'setupAxios';
import { useSnackbar } from 'react-simple-snackbar';
import messages from 'containers/Home/messages';
import {
  getEvents,
  getVisibleResources,
  setFilteredEvents,
  setFilteredResources,
  setFilteringTime,
  setLoading,
  setSelectedDate,
} from 'containers/Home/actions';
import { isEmpty } from 'lodash';
import humps from 'lodash-humps';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as dateUnitTypes from 'constants/dateUnitTypes';
import { uniqueId } from 'utils/commonUtils';
import * as color from 'constants/colors';

function buildResourcesAndEvents(data) {
  return data.resourceCalendarEventLists.reduce(
    (acc, resourceEvent) => {
      const { resource } = resourceEvent;

      switch (resource.providerType) {
        case 'google':
          // eslint-disable-next-line no-case-declarations
          const {
            name: googleName,
            capacity: googleCapacity,
            floorName,
            buildingId,
          } = resource.resourceGoogle;

          acc[0].push({
            resourceId: resource.uid,
            resourceTitle: googleName,
            capacity: googleCapacity || 0,
            floorName,
            buildingId,
          });
          break;
        case 'microsoft':
          // eslint-disable-next-line no-case-declarations
          const {
            name: msName,
            capacity: msCapacity,
            floorNumber,
            buildingName,
          } = resource.resourceMicrosoft;

          acc[0].push({
            resourceId: resource.uid,
            resourceTitle: msName,
            capacity: msCapacity || 0,
            floorName: floorNumber,
            buildingName,
          });
          break;
        case 'garoon':
          // eslint-disable-next-line no-case-declarations
          const {
            name: garoonName,
            facilityGroupName,
          } = resource.resourceGaroon;

          acc[0].push({
            resourceId: resource.uid,
            resourceTitle: garoonName,
            buildingId: facilityGroupName,
            providerType: resource.providerType,
            visible: true,
          });
          break;
        default:
      }

      acc[1] = acc[1].concat(
        resourceEvent.events.map(event => {
          return {
            ...event,
            ...{
              resourceId: resourceEvent.resource.uid,
              start: new Date(event.start),
              end: new Date(event.end),
            },
          };
        }),
      );
      // eslint-disable-next-line consistent-return
      return acc;
    },
    [[], []],
  );
}

const getResourceAndEvent = (notify, resourceUid, selectedDate) => {
  return async dispatch => {
    dispatch(showLoading());

    try {
      let query = '?date=';

      if (selectedDate) {
        query += `${moment(selectedDate).format('YYYY-MM-DD')}`;
      } else {
        query += `${moment().format('YYYY-MM-DD')}`;
      }

      const { data } = await axios(
        `/company/resources/${resourceUid}/calendar_events${query}`,
      ).catch(e => e.response);

      if (!data || (data && data?.error)) {
        notify.setError(
          messages.notifyAlert,
          data?.error?.message || messages.getResEvtErrSubMsg,
        );
        dispatch(setLoading(false));
        dispatch(hideLoading());
        dispatch(getVisibleResources([]));
        dispatch(getEvents([], selectedDate));
      } else if (!isEmpty(data)) {
        dispatch(hideLoading());
        dispatch(setLoading(false));
        if (!isEmpty(data?.resource_calendar_event_lists)) {
          const [resources, events] = buildResourcesAndEvents(humps(data));
          dispatch(getVisibleResources(resources));
          dispatch(getEvents(events, selectedDate));
        } else {
          dispatch(getVisibleResources([]));
          dispatch(getEvents([], selectedDate));
        }
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };
};

const snackBarErrorOptions = {
  position: 'top-center',
  style: {
    backgroundColor: color.errorRed,
    border: 'none',
    color: color.white,
    fontSize: '14px',
    textAlign: 'center',
  },
  closeStyle: {
    display: 'none',
  },
};

const snackBarSuccessOptions = {
  position: 'top-center',
  style: {
    backgroundColor: color.primaryGreen,
    border: 'none',
    color: color.white,
    fontSize: '14px',
    textAlign: 'center',
  },
  closeStyle: {
    display: 'none',
  },
};

const buildNotify = (success, error, intl) => {
  return {
    setError: message => error(intl.formatMessage(message)),
    setNotify: message => success(intl.formatMessage(message)),
  };
};

function useGetResourceEvent(resourceUid) {
  const intl = useIntl();
  const [successSnackbar] = useSnackbar(snackBarSuccessOptions);
  const [errorSnackbar] = useSnackbar(snackBarErrorOptions);
  const notify = buildNotify(successSnackbar, errorSnackbar, intl);
  const visibleResources = useSelector(
    state => state.resourceEvent?.visibleResources,
  );
  const events = useSelector(state => state.resourceEvent?.events);
  const filteringTime = useSelector(
    state => state.resourceEvent?.filteringTime,
  );
  const selectedDate = useSelector(state => state.resourceEvent?.selectedDate);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getResourceAndEvent(notify, resourceUid, selectedDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const setDate = date => {
    dispatch(setSelectedDate(date));
  };

  const filterByTime = filterTime => {
    dispatch(setFilteringTime(filterTime));
    if (filterTime) {
      const currentTime = new Date();
      const endTime = moment().add(filterTime, dateUnitTypes.MINUTES).toDate();
      const allEventsWithOccupy = [];

      const filterResources = resource => {
        let overlappingEventCount = 0;
        const eventsFiltered = events.filter(event => {
          if (resource.resourceId === event.resourceId) {
            if (
              (currentTime >= event.start && currentTime <= event.end) ||
              (endTime >= event.start && endTime <= event.end) ||
              (event.checkedInAt && !event.checkedOutAt)
            ) {
              overlappingEventCount += 1;
              return false;
            }
            return true;
          }
          return false;
        });

        if (overlappingEventCount === 0) {
          allEventsWithOccupy.push(...eventsFiltered, {
            name: intl.formatMessage(messages.occupy),
            start: currentTime,
            end: endTime,
            resourceId: resource.resourceId,
            id: uniqueId(),
            occupyNew: true,
          });
          return true;
        }
        return false;
      };

      const resourcesFiltered = visibleResources.filter(filterResources);
      dispatch(setFilteredResources(resourcesFiltered));
      dispatch(setFilteredEvents(allEventsWithOccupy));
    }
  };

  const deleteCalendarEvent = async (eventId, successCallback) => {
    dispatch(showLoading());
    try {
      const response = await axios(
        `/company/resources/${resourceUid}/calendar_events/${eventId}${
          selectedDate
            ? `?date=${moment(selectedDate).format('YYYY-MM-DD')}`
            : ''
        }`,
        {
          method: 'DELETE',
        },
      ).catch(e => e.response);
      const { data, status } = response;

      if (status > 399 && status < 500) {
        dispatch(hideLoading());
        notify.setError(
          messages.delEventErrMsg,
          data?.error?.message || messages.delEventErrSubMsg,
        );
      } else if (status === 500) {
        dispatch(hideLoading());
        notify.setError(messages.commonErrorMsg);
      } else if (status >= 200 && status < 298) {
        const latestEvents = events.filter(
          event => eventId !== event.id && eventId !== event.microsoftCalUid,
        );
        dispatch(getEvents(latestEvents));
        successCallback();
        dispatch(hideLoading());
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const forceGetResourceAndEvent = () => {
    dispatch(getResourceAndEvent(notify, resourceUid, selectedDate));
  };

  useEffect(() => {
    if (filteringTime) {
      filterByTime(filteringTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, filteringTime]);

  return {
    events,
    setDate,
    deleteCalendarEvent,
    forceGetResourceAndEvent,
  };
}

export { useGetResourceEvent };
