import { defineMessages } from 'react-intl';

export default defineMessages({
  summary: {
    id: 'resourceCalendarEventLists.resourceSettingGuide.summary',
    defaultMessage: 'Plase configure meeting room setting',
  },
  description: {
    id: 'resourceCalendarEventLists.resourceSettingGuide.description',
    defaultMessage:
      'Meeting rooms configuration is necessary for browsing meeting rooms availabilty.',
  },
});
