import React from 'react';
import { NavAccount } from '@d-lighted/design-system';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { PropTypes } from 'prop-types';

import { pushEvent, events } from 'utils/gtmUtils';
import { isMobileDevice } from 'utils/mobileUtils';
import { LOCALES, useLocale } from 'hooks/useLocale';
import { getCurrentUser } from 'utils/userUtils';
import AccountIcon from 'images/account.svg';
import IntegrationIcon from 'images/integration.svg';
import LogoutIcon from 'images/logout.svg';
import RoomsLogoIcon from 'images/img_logo_rooms.svg';
import { useOneTimeToken } from 'hooks/useOneTimeToken';
import * as webViewUtils from 'utils/webViewUtils';
import messages from './messages';
import {
  HeaderWrapper,
  HeaderRight,
  HeaderLeft,
  Icon,
  Anchor,
  IconTextWrapper,
  NewIcon,
  LangSelector,
  LangText,
} from './components/styled';
import { MobileHeader } from './components/Mobile_Header';

function Header({ setCurrentUser, hideRightComponent, styleProps, ...props }) {
  const [locale, setLocale] = useLocale();
  const { openWeb2RouteWithToken } = useOneTimeToken();
  const currentUser = getCurrentUser();
  const history = useHistory();
  const { pb } = styleProps;

  const accountList = [
    {
      label: <FormattedMessage {...messages.accountInfo} />,
      icon: AccountIcon,
      onClick: () => {
        openWeb2RouteWithToken('info/account');
        pushEvent({ ...events.onClickLinkApp('/info/account') });
      },
    },
    {
      label: <FormattedMessage {...messages.integrations} />,
      icon: IntegrationIcon,
      onClick: () => {
        openWeb2RouteWithToken('info/integrations');
        pushEvent({ ...events.onClickLinkApp('/info/integrations') });
      },
    },
    {
      label: <FormattedMessage {...messages.logout} />,
      icon: LogoutIcon,
      onClick: () => {
        window.localStorage.removeItem('auth_headers');
        window.localStorage.removeItem('refresh_token');
        window.localStorage.removeItem('currentUser');
        setCurrentUser({});
        window.location.reload();
      },
    },
  ];

  const webViewAccountList = [
    {
      label: <FormattedMessage {...messages.logout} />,
      icon: LogoutIcon,
      onClick: () => {
        webViewUtils.signOut();
      },
    },
  ];

  if (isMobileDevice()) {
    return (
      <MobileHeader {...props}>
        <NavAccount
          currentUser={currentUser}
          accountList={
            webViewUtils.isReactNativeWebView()
              ? webViewAccountList
              : accountList
          }
          showHr={false}
          showCompanyInfo={false}
        />
      </MobileHeader>
    );
  }

  return (
    <HeaderWrapper pb={pb}>
      <HeaderLeft>
        <Icon src={RoomsLogoIcon} onClick={() => history.push('/')} />
      </HeaderLeft>
      {!hideRightComponent && (
        <HeaderRight>
          <Anchor href={process.env.REACT_APP_HELPCENTER_URL} target="_blank">
            <FormattedMessage {...messages.helpCenter} />
          </Anchor>
          <IconTextWrapper>
            <NewIcon>NEW</NewIcon>
            <Anchor
              href={process.env.REACT_APP_SCHEDULING_APP_URL}
              target="_blank"
              ml="8px"
            >
              <FormattedMessage {...messages.schedulingApp} />
            </Anchor>
          </IconTextWrapper>
          <LangSelector>
            <LangText
              active={locale === LOCALES.EN}
              onClick={() => setLocale(LOCALES.EN)}
            >
              EN
            </LangText>
            <LangText
              active={locale === LOCALES.JA}
              onClick={() => setLocale(LOCALES.JA)}
            >
              JP
            </LangText>
          </LangSelector>
          <NavAccount
            currentUser={currentUser}
            accountList={accountList}
            showHr={false}
            showCompanyInfo={false}
          />
        </HeaderRight>
      )}
    </HeaderWrapper>
  );
}

Header.defaultProps = {
  hideRightComponent: false,
  styleProps: { pb: '' },
};

Header.propTypes = {
  setCurrentUser: PropTypes.func.isRequired,
  hideRightComponent: PropTypes.bool,
  styleProps: PropTypes.oneOfType([PropTypes.object]),
};

export default Header;
