import { FadeIn } from 'animate-css-styled-components';
import styled from 'styled-components';
import * as color from 'constants/colors';
import BaseButton from 'components/Buttons/BaseButton';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';

export const MainButton = styled(BaseButton)`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5.5px 16px 5px 35px;
  border-radius: 3px;
  background-color: ${color.primaryLightGreen};
  border: none;
  font-size: 16px;
`;

export const Icon = styled(FontAwesome)`
  font-size: 20px;
  margin-left: 15px;
`;

export const LinkItem = styled(Link)`
  position: relative;
  display: block;
  padding: 10px 25px;
  transition: all 0.2s linear;
  cursor: pointer;
  &:hover {
    background-color: ${color.hoverGray};
  }
`;

export const ClickableItem = styled.div`
  color: ${color.darkCyan};
  padding: 10px 25px;
  transition: all 0.2s linear;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: ${color.hoverGray};
  }
`;

export const ItemWrapper = styled(FadeIn)`
  z-index: 2;
  position: absolute;
  top: 47px;
  left: 0px;
  width: 100%;
  background-color: ${color.white};
  border-radius: 3px;
  box-shadow: 0 10px 26px 0 rgba(63, 78, 90, 0.14);
  padding: 5px;
  box-sizing: border-box;
`;
