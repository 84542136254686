import styled from 'styled-components';

import * as color from 'constants/colors';

export const Card = styled.div`
  padding: 6px 12px 6px;
  background-color: ${color.white};
  border-radius: 16px;
`;

export const StatusWrapper = styled.div`
  border-radius: 3px;
  border: ${props =>
    !props.isRoomBusy ? `1px solid ${color.brandBlue}` : null};
  box-sizing: border-box;
  padding: 2px 4px 3px;
  margin-left: 8px;
  background-color: ${props =>
    !props.isRoomBusy ? color.white : color.lighterRed};
`;

export const StatusText = styled.p`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: ${props => (!props.isRoomBusy ? color.brandBlue : color.errorRed)};
`;
