// TODO: The commented sections in this file are going to be required later
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash';
import moment from 'moment';
import {
  Label,
  RadioButton,
  Row,
  Col,
  TimePicker,
  Checkbox,
} from '@d-lighted/design-system';

import * as colors from 'constants/colors';
import { useValidation } from 'hooks/useValidation';
import { getCurrentUser } from 'utils/userUtils';
import humps from 'lodash-humps';
import messages from '../messages';
import SmallCalendar from './SmallCalendar';
import {
  SortingForm,
  SortingFooterContainer,
  ConfirmationDialogButton,
  TimeSelector,
  MeetingTime,
  MinuteSelect,
} from './styled';

const calculateMinuteSelectionOptions = (startTime, endTime) => {
  const result = [];
  const start = moment(startTime, 'HH:mm');
  const end = moment(endTime, 'HH:mm');
  const duration = moment.duration(end.diff(start)).asMinutes();

  for (let offset = 15; offset <= duration; offset += 15) {
    const durationObj = moment.duration(offset, 'minutes');
    const hours = durationObj.hours();
    const minutes = durationObj.minutes();
    const asMinutes = durationObj.asMinutes();

    const labelLong = `${hours > 0 ? `${hours}時間` : ''}${
      minutes > 9 ? minutes : `0${minutes}`
    }分`;
    const labelShort = `${asMinutes}分`;

    result.push({
      id: offset,
      value: offset,
      label: asMinutes > 60 ? labelLong : labelShort,
    });
  }

  return result;
};

function SortingMethods({ onSearch, searchValues }) {
  const initialFormData = {
    meetingTimes: { openingTime: '09:00', closingTime: '18:30' },
    holidays: false,
    desiredDate: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    ...searchValues,
  };

  const [minuteSelection, setMinuteSelection] = useState(
    calculateMinuteSelectionOptions(
      initialFormData.meetingTimes.openingTime,
      initialFormData.meetingTimes.closingTime,
    ),
  );

  const meetingTimes = [
    { msg: messages.thirtyMin, value: '30' },
    { msg: messages.sixtyMin, value: '60' },
    { msg: messages.ninetyMin, value: '90' },
    { msg: messages.other, value: 'other' },
  ];

  const [selectedTime, setSelectedTime] = useState(
    searchValues.duration != null ? searchValues.duration : '30',
  );
  const { required, dateArray } = useValidation();

  const handleTimeChange = (start, end, currentMeetingTimes) => {
    const newOptions = calculateMinuteSelectionOptions(
      start || currentMeetingTimes.openingTime,
      end || currentMeetingTimes.closingTime,
    );
    setMinuteSelection(newOptions);
  };

  const currentUser = getCurrentUser();
  const camelizedUser = humps(currentUser);
  // 明治安田生命保険相互
  const isMeijiyasudaCompanyUser = camelizedUser?.company?.id === 7129;

  return (
    <Form
      onSubmit={values => onSearch({ ...values, duration: selectedTime })}
      initialValues={initialFormData}
      initialValuesEqual={() => true}
    >
      {({ handleSubmit, values, valid }) => (
        <SortingForm onSubmit={handleSubmit}>
          <Label fontWeight="bold" color={colors.darkBlue} fontSize="16px">
            <FormattedMessage {...messages.sortingTitle} />
          </Label>

          <Row mt="24px">
            <Col>
              <Field
                name="methods"
                component={RadioButton}
                trueFor="allRooms"
                labelColor={colors.textBlack}
              >
                <FormattedMessage {...messages.showAllRooms} />
              </Field>
            </Col>
          </Row>
          <Row mt="20px">
            <Col>
              <Field
                name="methods"
                component={RadioButton}
                trueFor="available"
                labelColor={colors.textBlack}
              >
                <FormattedMessage {...messages.availableNow} />
              </Field>
            </Col>
          </Row>
          {!isMeijiyasudaCompanyUser && (
            <Row mt="20px">
              <Col>
                <Field
                  name="methods"
                  component={RadioButton}
                  trueFor="period"
                  labelColor={colors.textBlack}
                >
                  <FormattedMessage {...messages.specifyPeriod} />
                </Field>
              </Col>
            </Row>
          )}
          {values?.methods === 'period' && (
            <>
              <Row mt="36px">
                <Col>
                  <Label
                    color={colors.darkBlue}
                    fontSize="16px"
                    fontWeight="600"
                  >
                    <FormattedMessage {...messages.timeSlot} />
                  </Label>
                </Col>
              </Row>
              <Row pt="14px" alignItems="center">
                <Col display="flex" alignItems="center">
                  <Field
                    id="meetingTimes.openingTime"
                    name="meetingTimes.openingTime"
                    component={TimePicker}
                    maxTime={values?.meetingTimes?.closingTime}
                    onClose={e =>
                      handleTimeChange(e, null, values?.meetingTimes)
                    }
                  />
                </Col>
                <Col>-</Col>
                <Col>
                  <Field
                    id="meetingTimes.closingTime"
                    name="meetingTimes.closingTime"
                    component={TimePicker}
                    minTime={values?.meetingTimes?.openingTime}
                    onClose={e =>
                      handleTimeChange(null, e, values?.meetingTimes)
                    }
                  />
                </Col>
              </Row>

              <Row mt="36px">
                <Col>
                  <Label
                    fontWeight="600"
                    color={colors.darkBlue}
                    fontSize="16px"
                  >
                    <FormattedMessage {...messages.meetingTime} />
                  </Label>
                </Col>
              </Row>
              <Row mt="18px">
                {meetingTimes.map(meetTime => (
                  <Col xs="3" key={meetTime.msg.id}>
                    <TimeSelector
                      onClick={() => setSelectedTime(meetTime.value)}
                      isSelected={isEqual(meetTime.value, selectedTime)}
                    >
                      <MeetingTime
                        isSelected={isEqual(meetTime.value, selectedTime)}
                      >
                        <FormattedMessage {...meetTime.msg} />
                      </MeetingTime>
                    </TimeSelector>
                  </Col>
                ))}
              </Row>

              {isEqual(selectedTime, 'other') && (
                <Row pt="16px" alignItems="center">
                  {/* TODO: i18nize this when it's the time to do it */}
                  <Col xs="12">
                    <Field
                      name="minute"
                      component={MinuteSelect}
                      placeholder="会議時間を選んでください"
                      options={minuteSelection}
                      validate={required}
                    />
                  </Col>
                </Row>
              )}

              <Row mt="36px">
                <Col>
                  <Label
                    color={colors.darkBlue}
                    fontSize="16px"
                    fontWeight="600"
                  >
                    <FormattedMessage {...messages.period} />
                  </Label>
                </Col>
              </Row>
              <Row pt="20px">
                <Col>
                  <Field
                    id="holidays"
                    name="holidays"
                    component={Checkbox}
                    useArrayValue={false}
                  >
                    {/* <FormattedMessage {...messages.holidays} /> */}
                    土日祝も含める
                  </Field>
                </Col>
              </Row>
              <Field
                name="desiredDate"
                component={SmallCalendar}
                validate={dateArray}
              />
            </>
          )}
          <SortingFooterContainer>
            <ConfirmationDialogButton
              className="confirm_button"
              type="submit"
              disabled={!valid}
            >
              <FormattedMessage {...messages.searchButton} />
            </ConfirmationDialogButton>
          </SortingFooterContainer>
        </SortingForm>
      )}
    </Form>
  );
}

SortingMethods.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default SortingMethods;
