import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';

// eslint-disable-next-line no-useless-escape,max-len
const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
const companyEmailRegex = /^([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

const emailValidation = message => {
  return value => {
    return value && !emailRegex.test(value.toLowerCase()) ? message : undefined;
  };
};

const requiredValidation = message => {
  return value => (value || typeof value === 'number' ? undefined : message);
};

const dateArrayValidation = message => {
  return value => {
    return Array.isArray(value) && (value.length === 1 || value.length === 2)
      ? undefined
      : message;
  };
};

const companyEmailDomainValidation = message => {
  return value => {
    return value && !companyEmailRegex.test(value) ? message : undefined;
  };
};

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const useValidation = () => {
  const intl = useIntl();
  const email = useMemo(
    () => emailValidation(intl.formatMessage({ ...messages.email })),
    [intl],
  );
  const required = useMemo(
    () => requiredValidation(intl.formatMessage({ ...messages.required })),
    [intl],
  );
  const dateArray = useMemo(
    () => dateArrayValidation(intl.formatMessage({ ...messages.dateArray })),
    [intl],
  );
  const companyEmailDomain = useMemo(
    () =>
      companyEmailDomainValidation(
        intl.formatMessage({ ...messages.companyEmailDomain }),
      ),
    [intl],
  );

  return {
    email,
    required,
    dateArray,
    companyEmailDomain,
  };
};
