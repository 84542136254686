import { useDispatch, useSelector } from 'react-redux';
import axios from 'setupAxios';
import { isEmpty } from 'lodash';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import {
  getBuildings,
  setLoading,
  setSelectedBuilding,
} from 'containers/Home/actions';

function useBuildings() {
  const buildings = useSelector(state => state.resourceEvent?.buildings);
  const resourceProviders = useSelector(
    state => state.resourceEvent?.resourceProviders,
  );
  const selectedBuilding = useSelector(
    state => state.resourceEvent?.selectedBuilding,
  );
  const dispatch = useDispatch();

  const fetchResourceProviders = async () => {
    const { data } = await axios('/company/resource_providers', {
      method: 'GET',
    }).catch(e => e.response);

    if (!isEmpty(data?.resource_providers)) {
      return data?.resource_providers[0].provider_type;
    }

    return null;
  };

  const convertRoomListToBuilding = roomList => {
    return {
      building_id: roomList.address,
      room_list_address: roomList.address,
      building_name: roomList.name,
    };
  };

  const getURL = resourceProvider => {
    // TODO: 同じエンドポイントを叩くように修正する（API修正後）
    if (resourceProvider === 'microsoft') {
      return '/integrations/microsoft/room_lists?credentials_provider_type=resource_provider';
    }
    return '/company/resources/buildings';
  };

  // eslint-disable-next-line consistent-return
  const fetchBuildings = async () => {
    dispatch(showLoading());
    try {
      const resourceProvider = await fetchResourceProviders();

      if (resourceProvider == null) throw new Error('no_resource_provider');

      const URL = getURL(resourceProvider);

      const { data } = await axios(URL, { method: 'GET' }).catch(
        e => e.response,
      );

      if (!data || (data && data?.error)) {
        // TODO: Add some error window
      }

      // NOTE: google or garoon
      if (!isEmpty(data?.buildings)) {
        dispatch(getBuildings([...data?.buildings]));
      }

      // NOTE: microsoft
      if (!isEmpty(data?.room_lists)) {
        dispatch(
          getBuildings(
            data?.room_lists.map(item => convertRoomListToBuilding(item)),
          ),
        );
      }

      dispatch(hideLoading());
    } catch (error) {
      // TODO show no resource providers
      dispatch(hideLoading());
      return null;
    }
  };

  const setBuilding = building => {
    dispatch(setLoading(true));
    localStorage.setItem('selectedBuilding', JSON.stringify(building));
    dispatch(setSelectedBuilding(building));
  };

  return {
    fetchBuildings,
    buildings,
    setBuilding,
    selectedBuilding,
    resourceProviders,
  };
}

export { useBuildings };
