// TODO: The commented sections here aren't required for now
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from '@d-lighted/design-system';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import {
  ResourceRow,
  Pill,
  ResourceTitle,
  CardFirstRow,
  CardSecondaryText,
  // EntryButtonCol,
  // EntryButton,
} from './styled';
import messages from '../messages';

const ResourceCard = ({ resource, /*  onEnterRoomClick, */ events }) => {
  const history = useHistory();

  const {
    resourceTitle,
    capacity,
    buildingId,
    floorName,
    resourceId,
  } = resource;

  const isRoomBusy = () =>
    events.some(event => event.checkedInAt && !event.checkedOutAt);

  const renderAvailabilityPill = () => {
    if (!isRoomBusy()) {
      return (
        <Pill className="empty">
          <FormattedMessage {...messages.empty} />
        </Pill>
      );
    }

    return (
      <Pill className="error">
        <FormattedMessage {...messages.inUse} />
      </Pill>
    );
  };

  /* const handleEnterClick = e => {
    e.stopPropagation();
    onEnterRoomClick();
  }; */

  return (
    <ResourceRow
      onClick={() => {
        history.push(`/resource/${resourceId}`, {
          resourceId,
          resource,
        });
      }}
    >
      <Col xs={12}>
        <CardFirstRow>
          <Col>{renderAvailabilityPill()}</Col>
          <Col>
            <ResourceTitle>{resourceTitle}</ResourceTitle>
          </Col>
        </CardFirstRow>
        {capacity && (
          <Row>
            <Col>
              <CardSecondaryText>
                <FormattedMessage {...messages.capacity} />
                {capacity}
                <FormattedMessage {...messages.people} />
              </CardSecondaryText>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <CardSecondaryText>
              {buildingId}
              {floorName != null && `・${floorName}`}
              {floorName != null && <FormattedMessage {...messages.floor} />}
            </CardSecondaryText>
          </Col>
        </Row>
      </Col>
      {/* TODO: This element isnt required for now, hence disabled; Remove if not needed */}
      {/* <EntryButtonCol xs={4} display="none">
        <EntryButton outlined onClick={handleEnterClick}>
          入室
        </EntryButton>
      </EntryButtonCol> */}
    </ResourceRow>
  );
};

ResourceCard.propTypes = {
  resource: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onEnterRoomClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  events: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default ResourceCard;
