import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import axios from 'setupAxios';
import { isEmpty } from 'lodash';
import humps from 'lodash-humps';
import {
  setEvents,
  setNewCalendarEvent,
  setTemporaryEvent,
} from 'containers/Resource/actions';
import { timeRangeFormat } from 'utils/momentUtils';
import { uniqueId } from 'utils/commonUtils';

function useCalendarResource() {
  const events = useSelector(state => state.calendar?.events);
  const calendarEvent = useSelector(state => state.calendar?.calendarEvent);
  const tempEvent = useSelector(state => state.calendar?.temporaryEvent);

  const dispatch = useDispatch();

  const postCalendarEvent = async (
    payload,
    resourceUid,
    handleResult,
    setTempEvent,
  ) => {
    dispatch(showLoading());
    try {
      const { data } = await axios(
        `/company/resources/${resourceUid}/calendar_events`,
        {
          data: JSON.stringify({ event: payload }),
          method: 'POST',
        },
      ).catch(e => e.response);

      if (!data || (data && data?.error)) {
        handleResult('error', data?.error?.message);
        dispatch(hideLoading());
        setTempEvent({});
      } else if (!isEmpty(data) && !isEmpty(data?.calendar_event)) {
        const event = humps(data?.calendar_event);
        dispatch(setNewCalendarEvent(data?.calendar_event));
        const newEvent = {
          ...event,
          start: new Date(data?.calendar_event.start),
          end: new Date(data?.calendar_event.end),
          resourceId: resourceUid,
        };
        if (data?.calendar_event?.microsoft_cal_uid != null) {
          newEvent.microsoftCalUid = data?.calendar_event?.microsoft_cal_uid;
        }
        dispatch(setEvents([...events, newEvent]));
        handleResult('success');
        dispatch(hideLoading());
        setTempEvent({});
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const setInitialEvents = initialEvents => {
    dispatch(setEvents(initialEvents));
  };

  const setTempEvent = (eventSlot, timeFormat) => {
    if (!isEmpty(eventSlot)) {
      const name = timeRangeFormat(eventSlot, timeFormat, 'en');
      dispatch(
        setTemporaryEvent({
          start: eventSlot?.start,
          end: eventSlot?.end,
          resourceId: eventSlot?.resourceId,
          name,
          id: uniqueId(),
          temporary: true,
        }),
      );
    } else {
      dispatch(setTemporaryEvent({}));
    }
  };

  return {
    postCalendarEvent,
    calendarEvent,
    setInitialEvents,
    events,
    setTempEvent,
    tempEvent,
  };
}

export { useCalendarResource };
