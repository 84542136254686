import { defineMessages } from 'react-intl';

export default defineMessages({
  helpCenter: {
    id: 'header.helpCenter',
    defaultMessage: 'Help Center',
  },
  schedulingApp: {
    id: 'header.schedulingApp',
    defaultMessage: 'Scheduling App',
  },
  accountInfo: {
    id: 'header.dropdown.accountInfo',
    defaultMessage: 'Account Info',
  },
  integrations: {
    id: 'header.dropdown.integrations',
    defaultMessage: 'Integrations',
  },
  logout: {
    id: 'header.dropdown.logout',
    defaultMessage: 'Logout',
  },
});
