import { defineMessages } from 'react-intl';

export default defineMessages({
  building: {
    id: 'buildingSelector.building',
    defaultMessage: 'Building',
  },
  notSelected: {
    id: 'buildingSelector.notSelected',
    defaultMessage: 'Not Selected',
  },
  title: {
    id: 'buildingSelector.title',
    defaultMessage: 'Select a building',
  },
  buildingSelect: {
    id: 'buildingSelector.buildingSelect',
    defaultMessage: 'Select',
  },
  buildingSelected: {
    id: 'buildingSelector.buildingSelected',
    defaultMessage: 'Selected',
  },
  unselect: {
    id: 'buildingSelector.unselect',
    defaultMessage: 'No select a building',
  },
  notSelectedWarning: {
    id: 'buildingSelector.notSelectedWarning',
    defaultMessage:
      'It may be possible to get limited resource information if you did not select a building.',
  },
  noBuilding: {
    id: 'buildingSelector.noBuilding',
    defaultMessage: 'Buildings are not found',
  },
});
